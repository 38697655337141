(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/app/router/assets/javascripts/mcl-route.js') >= 0) return;  svs.modules.push('/components/app/router/assets/javascripts/mcl-route.js');
"use strict";

const _excluded = ["componentName", "componentProps", "getComponent", "onError", "onLoading"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }


const loadModuleComponent = svs.core.module.loadModuleComponent;
const useEffect = React.useEffect;
const useState = React.useState;
const Route = ReactRouterDOM.Route;
const logger = new svs.core.log.Logger('app:route:mcl-route');
const useMcl = _ref => {
  let {
    componentName,
    getComponent,
    componentProps,
    onError,
    onLoading
  } = _ref;
  const [error, setError] = useState();
  const [Component, setComponent] = useState();
  const [componentBundle, setComponentBundle] = useState({
    componentProps: null,
    onError: null,
    onLoading: null
  });
  const safeGetComponent = () => {
    try {
      return getComponent();
    } catch (e) {
      return undefined;
    }
  };
  useEffect(() => {
    setComponent(safeGetComponent);
    setComponentBundle({
      componentProps,
      onError,
      onLoading
    });
  }, [getComponent]); 

  useEffect(() => {
    let wasCancelled = false;
    if (!Component) {
      loadModuleComponent(componentName, err => {
        if (!wasCancelled) {
          if (err) {
            logger.error('Error loading module component', err);
            setError(err);
          } else {
            setComponent(safeGetComponent);
          }
        }
      });
    }
    return () => {
      wasCancelled = true;
    };
  }, [Component]);
  return [componentBundle.onError, componentBundle.onLoading, componentBundle.componentProps, error, Component];
};
const LoadMCl = props => {
  const [onError, onLoading, componentProps, error, Component] = useMcl(props);
  if (error && onError) {
    props.onError(error);
    return null;
  }
  if (!Component && onLoading) {
    props.onLoading();
    return null;
  }
  return Component ? React.createElement(Component, componentProps) : null;
};
const MclRoute = props => {
  const {
      componentName,
      componentProps,
      getComponent,
      onError,
      onLoading
    } = props,
    routeProps = _objectWithoutProperties(props, _excluded);
  return React.createElement(Route, _extends({}, routeProps, {
    render: () => React.createElement(LoadMCl, {
      componentName: componentName,
      componentProps: componentProps,
      getComponent: getComponent,
      onError: onError,
      onLoading: onLoading
    })
  }));
};
svs.app.ReactRouter.useMcl = useMcl;
svs.app.ReactRouter.MclRoute = MclRoute;

 })(window);